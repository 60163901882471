/* ==========================================================================

                        P A G E   D E S I G N E R

                Includes global definitions and components,
        their definitions are not too long and at the same time are
                   very common, for example Product List

========================================================================== */
@import "_settings/globalSetting";
.cc-page-designer {
    padding-bottom: rem-calc(40);

    /* Space between components */
    .cc-experience-component > .experience-component,
    .experience-content > .experience-component {
        margin-bottom: rem-calc(16);

        .cc-brandHomepage & {
            margin-bottom: rem-calc(24);
        }

        .cc-plp & {
            margin-bottom: 0;
        }
    }

    /* Global image element */
    .cc-component-image {
        position: relative;

        img {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            height: auto;
        }

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.20);
            pointer-events: none;
        }

        &.cc-hero-slider-slide {
            .cc-component-image-overlay {
                background: rgba(0, 0, 0, 0.10);
            }
        }

        &.cc-editorial-banner-low {
            .cc-img  {
                transform: scale(1.02, 1.02);
                transition: all 0.5s ease;
            }

            .cc-component-image-overlay {
                transition: all 0.5s ease;
            }

            &:hover {
                .cc-img  {
                    transform: scale(1, 1);
                }

                .cc-component-image-overlay {
                    background: rgba(0, 0, 0, 0.40);
                }
            }
        }

        &.cc-editorial-carousel-item {
            .cc-component-image-overlay {
                background: rgba(0, 0, 0, 0.15);
            }

            &:hover {
                .cc-component-image-overlay {
                    @include media-breakpoint-up(lg) {
                        background: rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }

        &:has(.cc-slide-link) {
            .cc-component-textbox {
                pointer-events: none;

                .cc-component-textbox-cta-link {
                    pointer-events: auto;
                }
            }
        }
    }

    /* Global video element */
    .cc-component-video {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        @include media-breakpoint-down(xs) {
            height: calc(100vh - var(--header-height)); /* minus header height */
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100vw;
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(xs) {
                height: 100vh;
                width: 178vh; /* 100 / (9 / 16) = 177.777777 */
            }
        }

        .cc-slide-link {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &:has(.cc-slide-link) {
            .cc-component-textbox {
                pointer-events: none;

                .cc-component-textbox-cta-link {
                    pointer-events: auto;
                }
            }
        }
    }

    /* Global element - predefined color variant of textbox */
    .cc-component {
        &-white-background-red-text {
            background: $white-warm;

            .cc-component-textbox {
                color: $red;
            }
        }

        &-white-background-black-text {
            background: $white-warm;

            .cc-component-textbox {
                color: $black;
            }
        }

        &-black-background-white-text {
            background: $black;

            .cc-component-textbox {
                color: $white;
            }
        }
    }

    /* Global textbox element */
    .cc-component-textbox {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        bottom: rem-calc(24);
        padding: 0 rem-calc(40);
        width: 100%;
        color: $white;

        @include media-breakpoint-down(xs) {
            &-cta-wrapper {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &.middle-centre {
            @include media-breakpoint-up(sm) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &-logo-up {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    .cc-component-textbox-logo {
                        position: absolute;
                        top: rem-calc(48);
                        margin: 0 rem-calc(40);
                    }
                }
            }
        }

        &.bottom-centre {
            @include media-breakpoint-up(sm) {
                bottom: rem-calc(136);
                left: 50%;
                transform: translateX(-50%);

                &-logo-up {
                    top: rem-calc(48);
                    bottom: rem-calc(64);
                    left: 50%;
                    transform: translateX(-50%);

                    .cc-component-textbox-logo {
                        margin-bottom: auto;
                    }
                }
            }
        }

        &.bottom-left {
            @include media-breakpoint-up(sm) {
                bottom: rem-calc(136);
                left: 0;
                align-items: flex-start;
                text-align: left;

                .cc-component-textbox-logo {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }

        &.middle-centre-mobile {
            @include media-breakpoint-down(xs) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.middle-centre-logo-up-mobile {
            @include media-breakpoint-down(xs) {
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                .cc-component-textbox-logo {
                    position: absolute;
                    top: rem-calc(24);
                    margin: 0 rem-calc(40);
                }
            }
        }

        &.bottom-centre-mobile {
            @include media-breakpoint-down(xs) {
                bottom: rem-calc(48);
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.bottom-centre-logo-up-mobile {
            @include media-breakpoint-down(xs) {
                top: rem-calc(24);
                bottom: rem-calc(24);
                left: 50%;
                transform: translateX(-50%);

                .cc-component-textbox-logo {
                    margin-bottom: auto;
                }
            }
        }

        &.bottom-left-mobile {
            @include media-breakpoint-down(xs) {
                bottom: rem-calc(48);
                left: 0;
                align-items: flex-start;
                text-align: left;

                .cc-component-textbox-cta-wrapper {
                    justify-content: flex-start;
                }

                .cc-component-textbox-logo {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }

        &-logo {
            margin-bottom: rem-calc(10);

            @include media-breakpoint-down(xs) {
                margin-bottom: rem-calc(6);
            }

            img {
                max-width: 100%;
                width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                filter: drop-shadow(0 0 #{rem-calc(25)} rgba(0, 0, 0, .5));

                @include media-breakpoint-up(sm) {
                    max-width: rem-calc(640);
                }

                @include media-breakpoint-up(xl) {
                    max-width: rem-calc(1000);
                }
            }
        }

        &-subtitle {
            font-size: rem-calc(24);
            line-height: rem-calc(28);
            letter-spacing: rem-calc(0.24);
            text-shadow: 0 0 rem-calc(25) rgba(0, 0, 0, 0.50);
            margin-bottom: rem-calc(14);

            @include media-breakpoint-down(xs) {
                margin-bottom: rem-calc(7);
                font-size: rem-calc(20);
                line-height: rem-calc(24);
            }

            &-style-highlighted-red {
                background: $red;
                display: inline-block;
                padding: rem-calc(4) rem-calc(10);
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $primary-font-extra-bold;
                letter-spacing: rem-calc(1.2);
                text-transform: uppercase;
                border-radius: rem-calc(2);
            }

            &-style-highlighted-grey {
                background: rgba($black-warm, 0.3);
                display: inline-block;
                padding: rem-calc(4) rem-calc(10);
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $primary-font-extra-bold;
                letter-spacing: rem-calc(1.2);
                text-transform: uppercase;
                backdrop-filter: blur(#{rem-calc(5)});
                border-radius: rem-calc(2);
            }
        }

        &-title {
            font-family: $secondary-font;
            font-weight: $secondary-font-bold;
            font-size: rem-calc(64);
            line-height: rem-calc(70);
            letter-spacing: rem-calc(0.32);
            text-transform: uppercase;
            text-shadow: 0 0 rem-calc(25) rgba(0, 0, 0, 0.50);

            @include media-breakpoint-down(xs) {
                font-size: rem-calc(46);
                line-height: rem-calc(54);
            }
        }

        &-description {
            font-size: rem-calc(15);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.15);
            margin-top: rem-calc(20);

            @include media-breakpoint-down(xs) {
                font-size: rem-calc(13);
                line-height: rem-calc(18);
            }
        }

        &.text-reverse {
            .cc-component-textbox-title {
                order: 1;
                margin-bottom: rem-calc(20);

                @include media-breakpoint-down(xs) {
                    margin-bottom: rem-calc(14);
                }
            }

            .cc-component-textbox-subtitle,
            .cc-component-textbox-logo {
                order: 2;
                margin-bottom: 0;
            }

            .cc-component-textbox-cta-wrapper {
                order: 3;
            }
        }

        &-cta-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: rem-calc(8);

            @include media-breakpoint-down(xs) {
                gap: rem-calc(4);
            }

            &:not(:first-child) {
                margin-top: rem-calc(46);

                @include media-breakpoint-down(xs) {
                    margin-top: rem-calc(32);
                }
            }

            &.cc-component-textbox-cta-style-button {
                .cc-component-textbox-cta {
                    @include animation();
                    min-width: rem-calc(180);
                    padding: rem-calc(11) rem-calc(20);
                    font-family: $primary-font;
                    font-weight: $primary-font-extra-bold;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(1.2);
                    text-transform: uppercase;
                    text-align: center;
                    border-radius: rem-calc(2);
                }
            }

            &.cc-component-textbox-cta-style-button-color-red {
                .cc-component-textbox-cta {
                    color: $white;
                    background: $red;
                    border: rem-calc(1) solid $red;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            background: $ruby-red;
                            border-color: $ruby-red;
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-black {
                .cc-component-textbox-cta {
                    color: $white;
                    background: $black-warm;
                    border: rem-calc(1) solid $black-warm;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            background: $red;
                            border-color: $red;
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-white {
                .cc-component-textbox-cta {
                    color: $black-warm;
                    background: $white;
                    border: rem-calc(1) solid $white;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-transparent,
            &.cc-component-textbox-cta-style-button-color-ghost-white {
                .cc-component-textbox-cta {
                    color: $white;
                    background: rgba($white, 0.1);
                    border: rem-calc(1) solid $white;
                    backdrop-filter: blur(#{rem-calc(5)});

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                            background: rgba($warm-grey-01, 0.15);
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-red-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $white;
                        background: $red;
                        border: rem-calc(1) solid $red;
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-black-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $white;
                        background: $black-warm;
                        border: rem-calc(1) solid $black-warm;
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-white-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $black-warm;
                        background: $white;
                        border: rem-calc(1) solid $white;
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-transparent-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $white;
                        background: rgba($white, 0.1);
                        border: rem-calc(1) solid $white;
                        backdrop-filter: blur(#{rem-calc(5)});
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-ghost-black {
                .cc-component-textbox-cta {
                    color: $black-warm;
                    border: rem-calc(1) solid $black-warm;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                            border-color: $red;
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-ghost-red {
                .cc-component-textbox-cta {
                    color: $red;
                    border: rem-calc(1) solid $red;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $ruby-red;
                            border-color: $ruby-red;
                        }
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-ghost-black-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $black-warm;
                        border: rem-calc(1) solid $black-warm;
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-ghost-red-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $red;
                        border: rem-calc(1) solid $red;
                    }
                }
            }

            &.cc-component-textbox-cta-style-button-color-ghost-white-mobile {
                .cc-component-textbox-cta {
                    @include media-breakpoint-down(xs) {
                        color: $white;
                        background: rgba($white, 0.1);
                        border: rem-calc(1) solid $white;
                        backdrop-filter: blur(#{rem-calc(5)});
                    }
                }
            }

            &.cc-component-textbox-cta-style-link {
                column-gap: rem-calc(32);

                @include media-breakpoint-down(xs) {
                    row-gap: rem-calc(16);
                }

                .cc-component-textbox-cta {
                    @include animation();
                    font-family: $primary-font;
                    font-weight: $primary-font-medium;
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    border-bottom: rem-calc(1) solid;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                            border-color: $red;
                        }
                    }
                }
            }
        }

        &-cta {
            display: inline-block;
        }

        &-text-color {
            &-black {
                color: $black-warm;

                .cc-component-textbox-cta-wrapper {
                    &.cc-component-textbox-cta-style-link {
                        .cc-component-textbox-cta {
                            &:hover {
                                @include media-breakpoint-up(lg) {
                                    color: $red;
                                    border-color: $red;
                                }
                            }
                        }
                    }
                }
            }

            &-white {
                color: $white;
            }

            &-red {
                color: $red;

                .cc-component-textbox-cta-wrapper {
                    &.cc-component-textbox-cta-style-link {
                        .cc-component-textbox-cta {
                            &:hover {
                                @include media-breakpoint-up(lg) {
                                    color: $ruby-red;
                                    border-color: $ruby-red;
                                }
                            }
                        }
                    }
                }
            }

            &-black-mobile {
                @include media-breakpoint-down(xs) {
                    color: $black-warm;
                }
            }

            &-white-mobile {
                @include media-breakpoint-down(xs) {
                    color: $white;
                }
            }

            &-red-mobile {
                @include media-breakpoint-down(xs) {
                    color: $red;
                }
            }
        }

        &-tooltip {
            display: inline-block;
            position: relative;
            transform: translateY(#{rem-calc(-10)});

            @include media-breakpoint-down(xs) {
                position: static;
                transform: none;
            }

            &-icon {
                display: inline-block;
                position: relative;
                vertical-align: top;
                margin-top: rem-calc(19);
                margin-left: rem-calc(6);
                z-index: 2;

                @include media-breakpoint-down(xs) {
                    margin-top: rem-calc(10);
                }

                &:after {
                    content: "";
                    position: absolute;
                    border-left: rem-calc(12) solid transparent;
                    border-right: rem-calc(12) solid transparent;
                    border-top: rem-calc(8) solid $white;
                    bottom: calc(100% + #{rem-calc(11)});
                    height: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    filter: drop-shadow(rem-calc(1) rem-calc(2) rem-calc(1) rgba(0,0,0,.1));
                    display: none;
                }

                svg {
                    width: rem-calc(12);
                    height: rem-calc(12);
                }
            }

            &-content {
                display: none;
                flex-direction: column;
                row-gap: rem-calc(12);
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                padding: rem-calc(12) rem-calc(16);
                width: rem-calc(300);
                background: $white;
                border-radius: rem-calc(2);
                color: $black-warm;
                font-family: $primary-font;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                text-transform: none;
                font-weight: $primary-font-medium;
                letter-spacing: rem-calc(0.13);
                box-shadow: 0 0 rem-calc(5) 0 rgba(0,0,0,0.3);

                @include media-breakpoint-down(xs) {
                    left: rem-calc(12);
                    right: rem-calc(12);
                    transform: none;
                    width: auto;
                    z-index: 1;
                    bottom: 97%;
                }

                &-cta {
                    border-bottom: rem-calc(1) solid $black-warm;

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                            border-color: $red;
                        }
                    }
                }
            }

            &:hover {
                .cc-component-textbox-tooltip-content {
                    display: flex;
                }

                .cc-component-textbox-tooltip-icon {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }

    /* Scroll arrow on Hero element (Hero Banner or Hero Video) */
    .cc-hero-component {
        position: relative;

        .cc-scroll-down {
            position: absolute;
            bottom: rem-calc(64);
            width: rem-calc(24);
            height: rem-calc(24);
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            right: 0;
            margin: auto;

            @include media-breakpoint-down(xs) {
                display: none;
            }

            &-location-bottom-right {
                left: auto;
                right: rem-calc(40);
                bottom: rem-calc(136);
            }

            svg {
                width: rem-calc(18);
                height: rem-calc(17);
                color: $white;
            }

            &:hover {
                svg {
                    color: $red;
                }
            }
        }
    }

    /* Product list component (including Einstein) */
    .cc-product-list {
        padding: 0;

        &-title,
        .cc-recommended-slider__title {
            font-weight: $primary-font-bold;
            font-size: rem-calc(24);
            line-height: rem-calc(30);
            padding: rem-calc(24) rem-calc(24) rem-calc(32) rem-calc(24);
            margin: 0;

            @include media-breakpoint-down(xs) {
                font-weight: $primary-font-semibold;
                font-size: rem-calc(22);
                line-height: rem-calc(26);
                padding: rem-calc(24) rem-calc(16);
            }
        }

        .cc-recommended-slider {
            padding: 0;
        }

        .cc-recommended-slider__carousel-wrp {
            margin: 0;
        }

        .cc-recommended-grid__carousel {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: auto;
            gap: rem-calc(1);

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }

        &.count-4 {
            .cc-recommended-grid__item {
                &:nth-child(n + 5) {
                    display: none;
                }
            }
        }

        &.count-8 {
            .cc-recommended-grid__item {
                &:nth-child(n + 9) {
                    display: none;
                }
            }
        }

        .container {
            padding: 0;
            max-width: none;
        }
    }

    .storepage & {
        padding-top: 0;
        padding-bottom: rem-calc(40);
    }
}
